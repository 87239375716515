import {listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class Pager extends Component {

	page = 1;

	constructor($, $$, props) {

		super(...arguments, {template: new Template($$.template)});

		listen($$.first,    "click", () => this.pageBy(1 - this.page));
		listen($$.previous, "click", () => this.pageBy(-1));
		listen($$.next,     "click", () => this.pageBy(1));

	}

	pageBy(offset) {
		this.page = Math.max(this.page + offset, 1);
		this.dispatch("page-by", {offset});
	}

	update(page, count, total) {
		this.page = page;
		this.template(this.$$.canvas, {page, count, total});
		this.$$.first.disabled = this.page == 1;
		this.$$.previous.disabled = this.page == 1;
		this.$$.next.disabled = total < count; // @todo: Replace "count" with "count * page" once a proper total is known.
	}

};
