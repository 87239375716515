import SearchTable from "./_search-table.js";

export default class PartsTable extends SearchTable {

	get action() {
		const url = new URL(`${$API_BASE_PATH}/parts`, location.origin);
		this.depotId != null && url.searchParams.set("depotId", this.depotId);
		this.provinceId != null && url.searchParams.set("provinceId", this.provinceId);
		url.searchParams.set("query", this.query);
		url.searchParams.set("page", this.page);
		return url;
	}

	search(depotId, provinceId, query) {
		this.depotId = depotId;
		this.provinceId = provinceId;
		super.search(query);
	}

	reset() {
		delete this.query;
		this.page = 1;
		this.load();
		this.dispatch("reset");
	}

};
