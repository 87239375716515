import Template from "../lib/template.js";
import Component, {createImageUrl} from "./_component.js";

export default class StockGrid extends Component {

	constructor($, $$, props) {
		super(...arguments, {template: new Template($$.template)});
	}

	async load(data) {
		this.data = data;
		this.data.forEach(item => {
			const {partId, modelName, modelMaterial, modelSize, modelLining} = item;
			item.textContent = [partId, modelName, modelMaterial, modelSize, modelLining].join(" ").toLowerCase();
		});
		this.props.wrapper != null && this.props.wrapper.style.removeProperty("--wrapper-min-height");
		this.data.imageBaseUrl = createImageUrl("__slug__", {clientWidth: 250, clientHeight: 100}).toString(); // URL object needs to be converted to a string (using its .toString), otherwise the data can't be cloned by the template.
		this.template(this.$, this.data);
	}

	filter(query) {
		// Freeze the grid wrapper height, so it doesn't jank when filtering rows:
		if(this.props.wrapper != null) {
			this.props.wrapper.style.setProperty("--wrapper-min-height", `${this.props.wrapper.offsetHeight}px`);
		}
		if(!query?.length) {
			Array.from(this.$.children).forEach(item => item.show());
		}
		else {
			for(const {index, textContent} of this.data) {
				this.$.children[index].hidden = !textContent.includes(query.toLowerCase());
			}
		}
	}

};
