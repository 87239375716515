import {listen, fetchJSON} from "../lib/component.js";
import Component from "./_component.js";

export default class Form extends Component {

	constructor($, $$, props) {

		super(...arguments);

		this.$F = $.elements;

		// Get the method from the form attribute, as the native FormElement.method property only supports GET and POST:
		Object.defineProperty($, "method", {
			get: () => $.getAttribute("method")
		});

		$.noValidate = true;

		listen($, "submit", () => !this.frozen && this.validate() && this.submit(), {preventDefault: true});

	}

	validate() {
		const valid = this.$.reportValidity();
		this.$.dataset.state = valid ? "valid" : "invalid";
		return valid;
	}

	async submit() {
		this.frozen = true;
		this.$$.button?.setAttribute("data-state", "pending");
		// Prepare a request:
		const request = {
			method: this.$.method.toUpperCase(),
			url:    new URL(this.$.action, location.origin)
		}
		if(this.serialize != null) {
			switch(request.method) {
				case "HEAD":
				case "GET":
					Object.entries(this.serialize()).forEach(([key, value]) => request.url.searchParams.set(key, value));
					break;
				default:
					request.body = this.serialize();
					break;
			}
		}
		try {
			this.data = await fetchJSON(request.method, request.url, request.body);
			this.$$.messageSuccess?.show();
		}
		catch(error) {
			this.$$.messageFailure?.show();
		}
		finally {
			this.$$.button?.hide();
		}
	}

}
