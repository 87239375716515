import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class CardForm extends Form {

	constructor($, $$, props) {

		super(...arguments);

		Object.defineProperty($, "action", {
			get: () => {
				const depotId = JSON.parse(sessionStorage.lastDepotId ?? null) ?? "@me";
				return `${$API_BASE_PATH}/users/@me/depots/${depotId}/cards`;
			}
		});

		listen($$.modeSelect,   "change", () => this.toggleMode());
		listen($$.debtorSelect, "change", () => this.toggleDebtor());

	}

	toggleMode() {
		for(const section of this.props.modeSections) {
			section.disabled = section.hidden = section.dataset.mode != String.fromInput(this.$F.mode);
			section.reset();
			!section.hidden && section.querySelector("[autofocus]")?.focus();
		}
		// Ensure that the mode selection survives a form reset:
		this.$F.mode.forEach(input => input.defaultChecked = input.checked);
		this.dispatch("toggle-mode");
	}

	toggleDebtor() {
		this.$$.newDebtor.disabled = this.$$.newDebtor.hidden = Number.fromInput(this.$$.debtorSelect) != 0;
	}

	serialize() {
		const debtorId = Number.fromInput(this.$$.debtorSelect);
		const payload = {
			partId:       this.partId,
			productionId: Number.fromInput(this.$F.productionId),
			dossierId:    String.fromInput(this.$F.dossierId),
			deceasedName: String.fromInput(this.$F.deceasedName)
		};
		switch(debtorId) {
			case 0:
				payload.newDebtorName =     String.fromInput(this.$F.newDebtorName),
				payload.newDebtorComments = String.fromInput(this.$F.newDebtorComments)
				break;
			default:
				payload.debtorId = debtorId;
				break;
		}
		return payload;
	}

	async submit() {
		await super.submit();
		this.reset();
		this.dispatch("submit");
	}

	reset() {
		delete this.partId;
		this.$.reset();
		this.dispatch("reset");
	}

};
