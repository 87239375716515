import {getComponentElements, getComponentProps} from "../lib/component.js";

export default class Component {

	static fromElement($) {

		// Instantiate the component:
		const name =  this.name.toKebabCase();
		const $$ =    getComponentElements($, name);
		const props = getComponentProps($, name);

		console.groupCollapsed(`%c${this.name}`, "color: cornflowerblue");
		console.log("%c$", "font-weight: bold", $);
		console.log("%c$$", "font-weight: bold", $$);
		console.log("%cprops", "font-weight: bold", props);
		console.groupEnd();

		// Clean up the component HTML:
		$.removeAttribute("@component");
		Object.entries($$).forEach(([key, element]) => element.removeAttribute(`@${name}:${key.toKebabCase()}`));
		Object.keys(props).forEach(key => $.removeAttribute(`@${name}:${key.toKebabCase()}`));

		return new this($, $$, props);

	}

	constructor($, $$, props, this_) {
		this.$ =     $;
		this.$$ =    $$;
		this.props = props;
		Object.assign(this, this_);
	}

	dispatch(types, detail, options={}) {
		const prefix = `@${this.constructor.name.toKebabCase()}:`;
		const options_ = {bubbles: true, detail, ...options};
		types.split(" ").forEach(type => this.$.dispatchEvent(new CustomEvent(prefix + type, options_)));
	}

};

export function createImageUrl(slug, canvas) {
	const params = {
		url:     new URL(`${$IMAGE_CDN_BASE_URL}/models/${slug}.png`),
		default: new URL(`${$IMAGE_CDN_BASE_URL}/models/_fallback.svg`),
		w:       canvas.clientWidth * devicePixelRatio,
		h:       canvas.clientHeight * devicePixelRatio,
		fit:     "inside",
		output:  "webp",
		q:       70
	};
	const url = new URL($IMAGE_PROXY_BASE_URL);
	Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));
	return url;
};

export function createImage(slug, title, canvas, signal) {
	return new Promise(async (resolve, reject) => {
		signal?.addEventListener("abort", () => reject(signal.reason));
		const image = new Image();
		image.title = title;
		image.src = createImageUrl(slug, canvas);
		await image.decode();
		resolve(image);
	});
};
