import {fetchJSON, listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Form from "./_form.js";

export default class AccountForm extends Form {

	constructor($, $$, props) {

		super(...arguments);

		if($$.defaultDepotTemplate != null) {
			this.defaultDepotTemplate = new Template($$.defaultDepotTemplate);
		}

		listen(this.$F.password,       "input", () => this.validatePasswords());
		listen(this.$F.passwordRepeat, "input", () => this.validatePasswords());

		this.load();

	}

	async load() {
		const user = await fetchJSON("GET", `${$API_BASE_PATH}/users/@me`);
		this.$F.name.value = user.name;
		this.$F.email.value = user.email;
		this.$$.defaultDepotSelect != null && this.defaultDepotTemplate(this.$$.defaultDepotSelect, user.depots);
	}

	validatePasswords() {
		this.$F.passwordRepeat.setCustomValidity(
			String.fromInput(this.$F.password) != String.fromInput(this.$F.passwordRepeat)
			? this.$F.passwordRepeat.dataset.messageInvalid
			: ""
		);
	}

	serialize() {
		return {
			name:           String.fromInput(this.$F.name),
			email:          String.fromInput(this.$F.email),
			password:       String.fromInput(this.$F.password),
			defaultDepotId: Number.fromInput(this.$F.defaultDepotId)
		}
	}

	async submit() {
		await super.submit();
		this.$F.password.value = "";
		this.$F.passwordRepeat.value = "";
	}

};
