import Form from "./_form.js";

export default class PasswordRequestForm extends Form {

	serialize() {
		return {email: String.fromInput(this.$F.email)};
	}

	async submit() {
		await super.submit();
		this.$.reset();
	}

};
