import Form from "./_form.js";

export default class LoginForm extends Form {

	serialize() {
		return {
			email:    String.fromInput(this.$F.email),
			password: String.fromInput(this.$F.password)
		};
	}

	async submit() {
		await super.submit();
		const params = document.component.params;
		location.assign(params.has("to") ? params.get("to") : `/app/${this.data.role}/`);
	}

};
