import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class PasswordResetForm extends Form {

	constructor($, $$, props) {

		super(...arguments);

		!document.component.params.has("token") && sleep(100, () => alert($.dataset.messageInvalid));

		listen(this.$F.password,       "input", () => this.validatePasswords());
		listen(this.$F.passwordRepeat, "input", () => this.validatePasswords());

	}

	validatePasswords() {
		this.$F.passwordRepeat.setCustomValidity(
			String.fromInput(this.$F.password) != String.fromInput(this.$F.passwordRepeat)
			? this.$F.passwordRepeat.dataset.messageInvalid
			: ""
		);
	}

	serialize() {
		return {
			token:    document.component.params.get("token"),
			password: String.fromInput(this.$F.password)
		};
	}

	async submit() {
		await super.submit();
		this.$.reset();
	}

};
