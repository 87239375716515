import {fetchJSON, listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

const $PAGE_COUNT = 25;

export default class SearchTable extends Component {

	page = 1;

	constructor($, $$, props) {
		super(...arguments, {template: new Template($$.template)});
		listen($$.canvas, "click", event => this.toggle(event.target.closest("tr")), {preventDefault: true});
	}

	async load(scroll=false) {
		scroll && this.props.container.scrollIntoView({behavior: "smooth"});
		this.data = await fetchJSON("GET", this.action);
		this.template(this.$$.canvas, this.data);
		this.dispatch("load", {page: this.page, count: $PAGE_COUNT, total: this.data.length});
	}

	toggle(target) {
		this.dispatch("toggle", {target, item: this.data[target.elementIndex]});
	}

	search(query) {
		this.query = query;
		this.page = 1;
		this.load();
		this.dispatch("search");
	}

	pageBy(offset) {
		this.page = Math.max(this.page + offset, 1);
		this.load(true);
	}

};
