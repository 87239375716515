import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class TabGroup extends Component {

	constructor($, $$, props) {
		super(...arguments);
		listen($, "change", event => this.toggle(String.fromInput(event.target)));
	}

	toggle(name) {
		this.props.sections.forEach(section => section.hidden = section.dataset.tab != name);
	}

};
