const $XPATH_RESULT_TYPE = XPathResult.ORDERED_NODE_SNAPSHOT_TYPE;

const filters = {

	encodeURI,
	encodeURIComponent,

	min:   Math.min,
	max:   Math.max,
	round: Math.round,
	floor: Math.floor,
	ceil:  Math.ceil,
	trunc: Math.trunc,

	toString: value => value.toString(),

	toJSON:   JSON.stringify,
	fromJSON: JSON.parse,

	ternary: (value, yep, nope) => !!value ? yep : nope,
	truthy:  value => !!value,
	falsy:   value => !value,

	is:        (a, b) => a == b,
	isNot:     (a, b) => a != b,
	isMore:    (a, b) => a > b,
	isLess:    (a, b) => a < b,
	isAtLeast: (a, b) => a >= b,
	isAtMost:  (a, b) => a <= b,

	inRange:   (value, min, max) => value >= min && value <= max,

	and:      (a, b) => a && b,
	or:       (a, b) => a || b,
	coalesce: (a, b) => a ?? b,

	add:      (...values) => values.reduce((a, b) => a + b),
	subtract: (...values) => values.reduce((a, b) => a - b),
	multiply: (...values) => values.reduce((a, b) => a * b),
	divide:   (...values) => values.reduce((a, b) => a / b),

	split:     (value, delimiter) => value.split(delimiter),
	includes:  (value, target) => value.includes(target),
	length:    value => value.length,

	toUpperCase:  value => value.toUpperCase(),
	toLowerCase:  value => value.toLowerCase(),
	toKebabCase:  value => value.toKebabCase(),
	toCamelCase:  value => value.toCamelCase(),
	toPascalCase: value => value.toPascalCase(),

	replace: (value, a, b) => value.replace(a, b),

	formatNumber: (value, style="short") => new Number(value).toLocaleString($INTL_LOCALE, {style}),
	formatDate:   (value, style="short") => new Date(value).toLocaleDateString($INTL_LOCALE, {dateStyle: style}),
	formatTime:   (value, style="short") => new Date(value).toLocaleTimeString($INTL_LOCALE, {timeStyle: style})

};

const parseValue = (value, context) => {
	const [expression, ...filters_] = value.split(/\s*\|\s*/);
	const value_ = parseExpression(expression, context);
	return filters_.reduce(
		(result, filter) => {
			const [name, ...args] = filter.match(/(?:[^\s"']+|["'][^"']*["'])+/g);
			return filters[name](result, ...args.map(arg => parseExpression(arg, context)));
		},
		value_
	);
};

const parseExpression = (expression, context) => {

	// String, Number or Boolean expression:
	if(/^["'](.*)["']$/.test(expression))        { return expression.slice(1, -1); }
	if(/^(\d+(\.\d*)?|\.\d+)$/.test(expression)) { return Number(expression); }
	if(["true", "false"].includes(expression))   { return expression == "true"; }

	// Tagged expression (string):
	if(expression.startsWith("=:")) { return JSON.parse(expression.substring(2)); }

	// Tagged expression (JSON):
	if(expression.startsWith("*:")) { return JSON.parse(expression.substring(2)); }

	// Context path expression:
	return expression.replace(/\[(\d+)\]/, ".$1").split(".").reduce((obj, key) => obj && obj[key], context);

};

export default class Template {

	constructor(template, remove=true) {
		this.template = template;
		this.root = document.implementation.createHTMLDocument().body;
		remove && template.remove();
		return (target, context, append)  => this.render(target, context, append);
	}

	getContext(element) {
		const contexts = [];
		while(!!element) {
			this.contexts.has(element) && contexts.unshift(this.contexts.get(element));
			element = element.parentNode;
		};
		return Object.assign(...contexts);
	}

	setContext(element, context) {
		this.contexts.set(element, {...this.contexts.get(element), ...context});
	}

	getElements(prefixes) {
		const selectors = prefixes.map(prefix => `starts-with(name(), "${prefix}")`).join(" or ");
		const query = `.//*[@*[${selectors}]]`;
		const result = new XPathEvaluator().evaluate(query, this.root, null, $XPATH_RESULT_TYPE);
		return Array.from(result).reverse().map(element => [
			element,
			Array.from(element.attributes).filter(attr => prefixes.some(prefix => attr.name.startsWith(prefix)))
		]);
	}

	getAttrs(pattern) {
		const query = `.//*/@*[contains(., "${pattern}")]`;
		const result = new XPathEvaluator().evaluate(query, this.root, null, $XPATH_RESULT_TYPE);
		return Array.from(result); // @todo: May not need Array.from().
	}

	getTextNodes(pattern) {
		const query = `//*/text()[contains(., "${pattern}")]`;
		const result = new XPathEvaluator().evaluate(query, this.root, null, $XPATH_RESULT_TYPE);
		return Array.from(result); // @todo: May not need Array.from().
	}

	render(target, context) {
		return new Promise((resolve, reject) => {

			const context_ = structuredClone(context);
			context_.$root = context_;

			this.root.replaceChildren();
			this.root.append(this.template.content.cloneNode(true));
			this.contexts = new WeakMap();
			this.setContext(this.root, context_);

			// $each:
			for(const [element, [attr]] of this.getElements(["$each:"])) {
				const key = attr.name.split(":")[1];
				const value = parseValue(attr.value, context_);
				const entries = Array.isArray(value) ? Array.from(value.entries()) : Object.entries(value);
				element.removeAttributeNode(attr);
				entries.forEach(([key_, value], i) => {
					const element_ = element.cloneNode(true);
					this.setContext(element_, {[key.toCamelCase()]: value, $key: key_, $index: i});
					element.before(element_);
				});
				element.remove();
			};

			// $set:
			for(const [element, attrs] of this.getElements(["$set:"])) {
				for(const attr of attrs) {
					const key = attr.name.split(":")[1];
					this.setContext(element, {[key.toCamelCase()]: parseValue(attr.value, this.getContext(element))});
					element.removeAttributeNode(attr);
				}
			};

			// $if and $unless:
			for(const [element, [attr]] of this.getElements(["$if", "$unless"])) {
				const unless = attr.name == "$unless";
				!(!!parseValue(attr.value, this.getContext(element)) ^ unless) && element.remove();
				element.removeAttributeNode(attr);
			};

			// Expression attributes:
			for(let attr of this.getAttrs("${")) {
				attr.value = attr.value.replace(
					/\${\s*(.+?)\s*}/g,
					(match, value) => parseValue(value, this.getContext(attr.ownerElement))
				);
			}

			// $attr:
			for(const [element, attrs] of this.getElements(["$attr:"])) {
				for(const attr of attrs) {
					const key = attr.name.split(":")[1];
					const value = parseValue(attr.value, this.getContext(element));
					key.startsWith("?") ? (!!value && element.setAttribute(key.substring(1), "")) : element.setAttribute(key, value);
					element.removeAttributeNode(attr);
				}
			};

			// $attr-if and $attr-unless:
			for(const [element, [attr]] of this.getElements(["$attr-if:", "$attr-unless:"])) {
				const unless = attr.name.startsWith("$attr-unless:");
				const key = attr.name.split(":")[1];
				!(!!parseValue(attr.value, this.getContext(element)) ^ unless) && element.removeAttribute(key);
				element.removeAttributeNode(attr);
			};

			// Expression text nodes:
			for(let node of this.getTextNodes("${")) {
				node.textContent = node.textContent.replace(
					/\${\s*(.+?)\s*}/g,
					(match, value) => parseValue(value, this.getContext(node.parentElement))
				);
			}

			// Replace <text> elements with their text content:
			for(let element of Array.from(this.root.getElementsByTagName("text"))) {
				element.replaceWith(element.textContent);
			}

			this.root.normalize();
			target.replaceChildren(...this.root.childNodes);
			resolve();

		});
	}

};
