import Select from "./select.js";

export default class DepotSelect extends Select {

	async load() {
		await super.load();
		const lastDepotId = JSON.parse(sessionStorage.lastDepotId ?? null);
		lastDepotId != null && (this.$.value = lastDepotId);
	}

	select() {
		sessionStorage.lastDepotId = this.selectedOption.id;
		super.select();
	}

};
