import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class Search extends Component {

	constructor($, $$, props) {

		super(...arguments);

		listen($, "submit", () => String.fromInput(this.$$.query) != null && this.search(), {preventDefault: true});
		listen($, "reset", () => this.reset());

	}

	search() {
		// Prevent consecutive searches with the same query:
		const query = String.fromInput(this.$$.query);
		if(query == this.query) {
			return;
		}
		this.query = query;
		this.dispatch("search", {query});
	}

	clear() {
		delete this.query;
		this.$.value = "";
	}

	reset() {
		this.clear();
		this.dispatch("reset");
	}

};
