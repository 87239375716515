const $DEFAULT_REQUEST_HEADERS = {
	"Accept":       "application/json",
	"Content-Type": "application/json"
};

const parseExpression = (expression, element) => {

	// String, Number or Boolean expression:
	if(/^["'](.*)["']$/.test(expression))        { return expression.slice(1, -1); }
	if(/^(\d+(\.\d*)?|\.\d+)$/.test(expression)) { return Number(expression); }
	if(["true", "false"].includes(expression))   { return expression == "true"; }

	// Tagged expression:
	if(/^(\*|#|\.?\${1,2}|\.\$\^):.+/.test(expression)) {
		const [tag, expression_] = expression.split(/:(.*)/);
		switch(tag) {
			case "*":   return JSON.parse(expression_);
			case "#":   return document.getElementById(expression_);
			case "$":   return document.querySelector(expression_);
			case "$$":  return document.querySelectorAll(expression_);
			case ".$":  return element.querySelector(expression_);
			case ".$$": return element.querySelectorAll(expression_);
			case ".$^": return element.closest(expression_);
		}
	}

	// Context path expression:
	return expression.replace(/\[(\d+)\]/, ".$1").split(".").reduce((obj, key) => obj && obj[key], window);

};

export function getComponentElements($, name) {
	// @todo: Allow elements names to be a dot-delimited path, then deep merge all elements.
	const query = `.//*[@*[starts-with(name(), "@${name}:")]]`;
	const result = new XPathEvaluator().evaluate(query, $, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE);
	return Object.fromEntries(
		Array.from(result).map(element => {
			const {name: name_} = Array.from(element.attributes).find(({name: name_}) => name_.startsWith(`@${name}:`));
			return [name_.split(":")[1].toCamelCase(), element];
		})
	);
};

export function getComponentProps($, name) {
	// @todo: Allow prop names to be a dot-delimited path, then deep merge all props.
	return Object.fromEntries(
		Array.from($.attributes)
		.filter(({name: name_}) => name_.startsWith(`@${name}:`))
		.map(({name: name_, value}) => [name_.split(":")[1].toCamelCase(), parseExpression(value, $)])
	);
};

export function listen(targets, types, handler, options={}) {
	const options_ = {preventDefault: false, passive: !options.preventDefault, ...options};
	const handler_ = (event, ...args) => {
		options_.preventDefault && event.preventDefault();
		handler != null && handler(event, ...args);
	};
	for(const target of [targets].flat()) {
		types.split(" ").forEach(type => {
			if(/^key(down|up):.+/.test(type)) {
				const [type_, key] = type.split(":");
				const handler__ = (event, ...args) => event.key == key.toPascalCase() && handler_(event, ...args);
				target.addEventListener(type_, handler__, options_);
				return handler__;
			}
			target.addEventListener(type, handler_, options_);
		});
	}
	return handler_;
};

export function unlisten(targets, types, handler) {
	for(const target of [targets].flat()) {
		types.split(" ").forEach(type => target.removeEventListener(type, handler));
	}
};

export async function fetchJSON(method, url, body) {
	const response = await fetch(url, {method, headers: $DEFAULT_REQUEST_HEADERS, body: JSON.stringify(body)});
	if(!response.ok) {
		throw `${response.status} ${response.statusText}`;
	}
	if(response.headers.get("Content-Type") != "application/json") {
		return;
	}
	return await response.json();
};
