import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class SignupForm extends Form {

	constructor($, $$, props) {

		super(...arguments);

		listen(this.$F.password,       "input", () => this.validatePasswords());
		listen(this.$F.passwordRepeat, "input", () => this.validatePasswords());

		// @debug:
		listen(document, "keydown:f1", () => {
			this.$F.company.value =        "Test";
			this.$F.name.value =           "Test";
			this.$F.email.value =          "test@test.test";
			this.$F.password.value =       "testtest";
			this.$F.passwordRepeat.value = "testtest";
		});

	}

	validatePasswords() {
		this.$F.passwordRepeat.setCustomValidity(
			String.fromInput(this.$F.password) != String.fromInput(this.$F.passwordRepeat)
			? this.$F.passwordRepeat.dataset.messageInvalid
			: ""
		);
	}

	serialize() {
		return {
			company:  String.fromInput(this.$F.company),
			name:     String.fromInput(this.$F.name),
			email:    String.fromInput(this.$F.email),
			password: String.fromInput(this.$F.password)
		};
	}

	async submit() {
		await super.submit();
		this.$.reset();
	}

};
