String.prototype.toCamelCase =  function() { return this.replace(/[^\w]+(.)/g, (match, char) => char.toUpperCase()); };
String.prototype.toKebabCase =  function() { return this.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(); };
String.prototype.toPascalCase = function() { return this.toCamelCase().replace(/^\w/, char => char.toUpperCase()); };

String.fromInput = (input, default_, empty=false) => !!input?.value.length || empty ? String(input.value) : default_;
Number.fromInput = (input, default_) => !!input?.value.length ? Number(input.value) : default_;

// Prototype extension to make XPathResult iterable:
// Source: https://www.anycodings.com/1questions/1320706/how-to-use-arrayfrom-with-a-xpathresult
XPathResult.prototype[Symbol.iterator] = function* () {
	switch(this.resultType) {
		case XPathResult.UNORDERED_NODE_ITERATOR_TYPE:
		case XPathResult.ORDERED_NODE_ITERATOR_TYPE:
			let result;
			while(result = this.iterateNext()) {
				yield result;
			}
			break;
		case XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE:
		case XPathResult.ORDERED_NODE_SNAPSHOT_TYPE:
			for(let i=0; i < this.snapshotLength; i++) {
				yield this.snapshotItem(i);
			}
			break;
		default:
			yield this.singleNodeValue;
			break;
	}
};

Object.defineProperty(HTMLElement.prototype, "elementIndex", {
	get: function() {
		return Array.prototype.indexOf.call(this.parentElement.children, this);
	}
});

HTMLElement.prototype.show = function() { this.hidden = false; };
HTMLElement.prototype.hide = function() { this.hidden = true; };

HTMLFieldSetElement.prototype.reset = function() {
	Array.from(this.elements).forEach(input => input.value = input.defaultValue);
	this.dispatchEvent(new Event("reset", {bubbles: true}));
};

window.sleep = (delay, callback, signal) => {
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(
			() => {
				callback != null && callback();
				resolve();
			},
			delay
		);
		signal?.addEventListener("abort", () => {
			clearTimeout(timeout);
			reject(signal.reason);
		});
	});
};

window.debounce = (delay, callback) => {
	let timeout;
	return () => {
		clearTimeout(timeout);
		timeout = setTimeout(callback, delay);
	};
}

const script = document.head.querySelector("[data-env]");
Object.assign(window, JSON.parse(script.innerText));
script.remove();
