import Component from "./_component.js";

export default class Viewport extends Component {

		// Override the component root, because document.documentElement is tool long to type ;)
		constructor($, $$, props) {
		super(document, $$, props, {params: new URLSearchParams(location.search.substring(1))});
	}

};
