import {listen} from "../lib/component.js";
import Controller from "./_controller.js";

export default class CardFormController extends Controller {

	initialize() {

		const form =   this.$$.form.component;
		const lookup = this.$$.lookup.component;
		const card =   this.$$.card.component;
		const dialog = this.$$.dialog.component;
		const filter = this.$$.filter.component;
		const table =  this.$$.table.component;

		listen(document, "@depot-select:select", () => {
			delete form.partId;
			lookup.clear();
			filter.clear();
			table.load();
			card.reset();
		});

		listen(form.$, "@card-form:toggle-mode @card-form:reset", () => lookup.reset());

		listen(lookup.$, "@stock-lookup:lookup", event => {
			form.partId = event.detail.item.partId;
			card.fill(event.detail.item);
		});
		listen(lookup.$, "@stock-lookup:reset",  () => {
			delete form.partId;
			card.reset();
		});

		if(document.component.params.has(lookup.$.name)) {
			lookup.$.value = document.component.params.get(lookup.$.name);
			lookup.$.validity.valid && lookup.lookup();
		}

		listen(filter.$, "@filter:filter", event => table.filter(event.detail.query));

		listen(table.$, "@stock-table:toggle", async event => {
			const item = event.detail.item;
			if(item.quantity < 1) {
				return;
			}
			form.partId = item.partId;
			card.fill(item);
			const input = event.detail.target.querySelector('input[type="radio"]');
			requestAnimationFrame(() => input.checked = true);
			await sleep(250);
			await dialog.close();
			input.checked = false;
		});

		listen(this.$$.toggle, "click", () => dialog.open(this.$$.toggle, true), {preventDefault: true});

	}

};
