import {listen} from "../lib/component.js";
import Controller from "./_controller.js";

export default class PartsTableController extends Controller {

	initialize() {

		const search = this.$$.search.component;
		const table =  this.$$.table.component;
		const pager =  this.$$.pager.component;
		const dialog = this.$$.dialog.component;

		listen(
			search.$,
			"@parts-search:search",
			event => table.search(event.detail.depotId, event.detail.provinceId, event.detail.query)
		);
		listen(search.$, "@parts-search:reset",  () => table.reset());

		listen(table.$, "@parts-table:load", event => {
			table.$.show();
			pager.$.show();
			pager.update(event.detail.page, event.detail.count, event.detail.total);
		});
		listen(table.$, "@parts-table:toggle", event => dialog.toggle(event.detail.target, event.detail.item));

		listen(pager.$, "@pager:page-by", event => table.pageBy(event.detail.offset));

	}

};
