import Search from "./search.js";

export default class PartsSearch extends Search {

	search() {
		const depotId = Number.fromInput(this.$$.depotSelect);
		const provinceId = String.fromInput(this.$$.provinceSelect);
		const query = String.fromInput(this.$$.query);``
		// Prevent consecutive searches with the same parameters:
		if(depotId == this.depotId && provinceId == this.provinceId && query == this.query) {
			return;
		}
		this.depotId = depotId;
		this.provinceId = provinceId;
		this.query = query;
		this.dispatch("search", {depotId, provinceId, query});
	}

};
