import {listen} from "../lib/component.js";
import Controller from "./_controller.js";

export default class StockTableController extends Controller {

	initialize() {

		const filter = this.$$.filter.component;
		const table =  this.$$.table.component;
		const grid =   this.$$.grid.component;
		const dialog = this.$$.dialog.component;

		listen(document, "@depot-select:select", () => {
			filter.clear();
			table.reset();
		});

		listen(filter.$, "@filter:filter", event => table.filter(event.detail.query));

		listen(table.$, "@stock-table:load",   event => grid.load(event.detail.data));
		listen(table.$, "@stock-table:filter", event => grid.filter(event.detail.query));
		listen(table.$, "@stock-table:toggle", event => dialog.toggle(event.detail.target, event.detail.item));

	}

};
