import SearchTable from "./_search-table.js";

export default class CardsTable extends SearchTable {

	constructor($, $$, props) {
		super(...arguments);
		this.load();
	}

	get action() {
		const depotId = JSON.parse(sessionStorage.lastDepotId ?? null) ?? "@me";
		const url = new URL(`${$API_BASE_PATH}/users/@me/depots/${depotId}/cards`, location.origin);
		this.query != null && url.searchParams.set("query", this.query);
		url.searchParams.set("page", this.page);
		return url;
	}

	reset() {
		delete this.query;
		this.page = 1;
		this.load();
		this.dispatch("reset");
	}

};
